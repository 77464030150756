$content-width: 120rem;
$navbar-height: 5rem;

$font-size-h1: 5rem;
$font-size-h2: 4rem;
$font-size-h3: 3rem;
$font-size-h4: 2.5rem;
$font-size-h5: 2rem;
$font-size-h6: 1.7rem;
$font-size-large: 1.5rem;
$font-size-normal: 1.3rem;
$font-size-small: 1.1rem;

$sans-serif: Raleway, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
$monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

$gray-primary-color: #d2c2aa;
$secondary-color: #1f59c9;
$highlight-color: #ed1d24;
$link-color: black;
$link-hover-color: darkgray;

@mixin small-screen {
    @media (max-width: 700px) {
        @content;
    }
}

@mixin below-width($width) {
    @media (max-width: $width) {
        @content;
    }
}

// Cross-browser animation mixins from https://joshbroton.com/quick-fix-sass-mixins-for-css-keyframe-animations/
@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';

    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};

        @if $i < $max {
            $animations: #{$animations + ', '};
        }
    }
    -webkit-animation: $animations;
    -moz-animation:    $animations;
    -o-animation:      $animations;
    animation:         $animations;
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($args...) {
  -webkit-transform: $args;
  -moz-transform: $args;
  -ms-transform: $args;
  -o-transform: $args;
  transform: $args;
}

@include keyframes(doubleBounce) {
    0% {
        @include transform(translateY(0));
    }

    25% {
        @include transform(translateY(10px));
    }

    50% {
        @include transform(translateY(0));
    }

    75% {
        @include transform(translateY(10px));
    }

    100% {
        @include transform(translateY(0));
    }
}
