@import "../../globals.scss";

$cutoff-width: 1100px;

.service {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-repeat: no-repeat;

    &__heading {
        font-weight: 900;
        font-size: $font-size-h1;

        @include small-screen {
            font-size: $font-size-h2;
        }
    }

    &__content-wrapper {
        flex-grow: 2;
        display: flex;
        margin-top: 4rem;

        @include below-width($cutoff-width) {
            flex-direction: column;
        }
    }

    &__copy {
        background-color: rgba($color: #000000, $alpha: 0.3);
        flex: 2;
        color: white;
        padding: 8rem 8rem 6rem;
        max-width: 45ch;
        font-size: $font-size-h5;

        @include below-width($cutoff-width) {
            max-width: 100%;
        }

        @include small-screen {
            padding: 4rem 2rem;
        }

        p {
            font-size: $font-size-h5;
            margin-top: 4rem;
            text-align: justify;

            @include small-screen {
                font-size: $font-size-h6;
                margin-top: 2rem;
            }
        }
    }

    &__services-wrapper {
        padding: 8rem 8rem 6rem;
        display: flex;
        flex-wrap: wrap;
        flex: 3;
        align-content: flex-start;

        @include small-screen {
            padding: 2rem;
        }
    }

    &__service-wrapper {
        color: white;
        font-weight: 900;
        margin: 4rem;
        flex: 1;
        text-align: center;
        font-size: $font-size-h2;

        @include small-screen {
            font-size: $font-size-h3;
        }
    }

    &__link {
        text-decoration: none;
        color: white;
        display: block;

        @include transition(color 0.2s, transform 0.2s);

        &:hover {
            @include transform(scale(1.2));
            color: $highlight-color;
        }
    }
}
