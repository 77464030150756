@import "../../globals.scss";

.footer {
    text-align: center;
    padding: 1rem 0;
    background-color: $gray-primary-color;
    color: black;

    &__text {
        margin: 0.5rem auto;
    }

    &__disclaimer {
        font-size: 10px;
    }
}
