@import "../../globals.scss";

$cutoff-width: 1100px;

.services {
    background-image: url('../../images/mixer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;

    &__flex {
        display: flex;
        height: 100%;

        @include below-width($cutoff-width) {
            flex-direction: column;
        }
    }

    &__copy {
        background-color: rgba($color: #000000, $alpha: 0.3);
        flex: 2;
        color: white;
        padding: 8rem 8rem 6rem;
        max-width: 45ch;
        font-size: $font-size-h5;

        @include below-width($cutoff-width) {
            max-width: 100%;
        }
        
        p {
            font-size: $font-size-h5;
            margin-top: 4rem;
            text-align: justify;

            @include small-screen {
                font-size: $font-size-h6;
                margin-top: 2rem;
            }
        }

        @include small-screen {
            padding: 2rem;
        }
    }

    &__heading {
        font-weight: 900;
        font-size: $font-size-h1;
    }

    &__services-wrapper {
        flex: 3;
        display: flex;
        justify-content: space-around;
        padding: 8rem;
        flex-wrap: wrap;
        align-content: center;

        @include small-screen {
            padding: 4rem;
        }
    }

    &__service-wrapper {
        color: white;
        font-weight: 900;
        margin: 4rem;
        flex: 1;
        text-align: center;
        font-size: $font-size-h2;

        @include small-screen {
            font-size: $font-size-h3;
            margin: 2rem;
        }
    }
    
    &__link {
        text-decoration: none;
        color: white;
        display: block;

        @include transition(color 0.2s, transform 0.2s);

        &:hover {
            @include transform(scale(1.2));
            color: $highlight-color;
        }
    }
}