@import "../../globals.scss";

.navbar {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 4rem;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  z-index: 3;
  position: fixed;
  top: 0;
  background-color: transparent;
  @include transition(background-color 0.3s, height 0.1s);

  &.active {
    background-color: $gray-primary-color;
    border-bottom: 1px solid #888;

    .navbar__logo {
      max-height: 5rem;

      @include small-screen {
        max-height: 4rem;
      }
    }

    .navbar__company-name {
      padding-left: 1rem;
      opacity: 1;
      width: auto;
    }

    .navbar__link {
      color: black;
      margin-top: 0;

      &:hover {
        color: $highlight-color;
      }
    }

    .icon {
      color: black;
    }

    @include small-screen {
      .navbar__link {
        @include transition(background-color 0.3s);
      }
    }
  }

  &__home {
    display: flex;
    align-items: center;
    text-decoration: none;
    
    @include small-screen {
      align-items: flex-start;
    }

    &:hover {
      color: black;
    }
  }

  &__logo {
    max-height: 12rem;

    @include small-screen {
      max-height: 6rem;
    }

    @include transition(max-height 0.2s);
  }

  &__company-name {
    display: flex;
    align-items: center;
    font-size: $font-size-h5;
    font-weight: 900;
    opacity: 0;
    white-space: nowrap;
    width: 0;
    overflow: hidden;

    @include transition(opacity 0.2s, padding 0.2s);

    @include small-screen {
      font-size: $font-size-h6;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__mastersound {
    font-weight: 600;
    margin-left: 0.6rem;

    @include small-screen {
      margin-left: 0;
    }
  }

  &__links {
    display: flex;
    align-items: center;
  }

  &__link {
    margin-top: -4rem;
    padding: 2rem 0;
    margin-left: 3rem;
    font-size: $font-size-large;
    color: white;
    font-weight: 900;
    text-decoration: none;

    &:hover {
      color: $highlight-color;
    }

    @include transition(margin-top 0.2s, color 0.18s);
  }

  .icon {
    color: white;
    display: none;
    padding: 2rem 2rem 1rem;
    @include transition(color 0.2s);
  }

  @include small-screen {
    padding: 1rem 2rem;

    &__heading {
      font-size: $font-size-small;
    }

    &__links {
      flex-direction: column;
      text-align: right;
      align-items: flex-end;
    }

    &__link {
      background-color: transparent;
      margin: 0 -2rem 0 0;
      padding: 1rem;
      font-size: $font-size-small;

      &.closed {
        opacity: 0%;
        display: none;
      }

      &.open {
        @include animation("fadeInFromNone .2s");
      }
    }

    .icon {
      display: block;
      font-size: 1.5rem;
      padding: 0;
      text-align: right;
    }
  }
}
